<template>
  <div>
    <CommonStandardList
        slug="Company"
        module-name="cm-company"
        :table-headers="tableHeaders"
        :form-setting="form"
        :api-urls="apiUrls"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {onUnmounted, ref} from "@vue/composition-api"

// sidebar
import CommonStandardList from "@/views/common/list/standard-list/CommonStandardList"
import store from "@/store"
import companyStoreModule from "@/views/company-management/companies/companyStoreModule"
import useCompaniesList from "@/views/company-management/companies/company-list/useCompaniesList"

export default {
  components: {
    CommonStandardList
  },
  mounted() {
    this.fetchAllGroups();
  },
  setup() {

    const USER_APP_STORE_MODULE_NAME = "cm-company"

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })


    const {
      fetchAllGroups,
      allGroups
    } = useCompaniesList()

    const apiUrls = ref(
        {
          list: "/admin/company",
          add: "/admin/company"
        }
    )

    const tableHeaders = ref([
      {text: "NAME", value: "name", filter: true},
      {text: "ADDRESS", value: "address"},
      {text: "PHONE", value: "phone"},
      {text: "CREATED AT", value: "created_at", sortable: false},
      {
        text: "ACTIONS",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ])

    const form = ref([
      {name: "name", type: "text", required: true},
      {name: "address", type: "text", required: true},
      {name: "phone", type: "text", required: true},
      {name: "company_group_id", type: "select", required: false, options: allGroups},
    ])

    const searches = []

    return {
      tableHeaders,
      form,
      apiUrls,
      fetchAllGroups,
      // icons
      icons: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
